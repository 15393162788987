import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import useStyles from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import GameImage from '../../assets/images/game.jpeg';
import useWindowDimensions from '../../utils/windowDimention';
import Loader from "../../components/Common/Loader";
import InnerPageHeader from '../../components/InnerPageHeader';
import Placeholder from '../../assets/images/common-images/user_placeholder.png'

import {
  getNotifications
} from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Notification = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getNotifications();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
          {data.length > 0 && data.map((item,index) => {
            let format = item.text_format
            let values = JSON.parse(JSON.stringify(JSON.parse(item.text_values)));
              for (let key in values) {
                var value = values[key]
                format = format.replace(`%${key}%`,value.trim())
              }
              return (<div className={classes.notifContainer} key={item.id} style={{backgroundColor: index % 2 === 0 ? '#303030' : 'transparent'}}>
                <img src={item.source.avatar_small ? item.source.avatar_small : Placeholder} className={classes.profileImage} style={{
                  width: 48,
                  height: 48,
                  objectFit: "cover",
                  borderRadius: 24,
                }}/>
                <p className={classes.textFormat}>{format}</p>
              </div>)}
            )
            }
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 20, paddingRight: 40, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="Notification" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Notification;
